import axios from 'axios';

export const askQuestion = async (question) => {
    let url = `https://stg3.job-applications.vahan.co/ask`;
    return axios.post(url, question);
};

export const fetchFaq = async (question) => {
    let url = `https://stg3.job-applications.vahan.co/faqs`;
    return axios.get(url);
};
